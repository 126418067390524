import React from "react";

export const userVar = () => {
    return (
        <div>
            <span className="username">guest</span>
            <span className="text_ast">@</span>
            <span className="text_term">rickyli</span>
            <span className="text_ast">:$ ~ </span>
        </div>
    );
};

export default userVar;
