import * as bin from "./index";

export const help = async (args: string[]): Promise<string> => {
    const commands = Object.keys(bin).sort().join("\n");

    return `Available commands:
    \n${commands}\n  
    [tab]\t trigger completion. \n
    [ctrl+l] clear terminal. \n
    [ctrl+c] cancel command. \n`;
};

export const home = async (args: string[]): Promise<string> => {
    setTimeout(function () {
        window.open("https://rickanli.dev", "_self");
    }, 1000);

    return "Returning to home page...";
};

export const whoami = async (args: string[]): Promise<string> => {
    return "My name is Rickan, I also go by Ricky, and I'm a software engineer.";
};

export const banner = async (args: string[]): Promise<string> => {
    return `

██████╗ ██╗ ██████╗██╗  ██╗ █████╗ ███╗   ██╗    ██╗     ██╗
██╔══██╗██║██╔════╝██║ ██╔╝██╔══██╗████╗  ██║    ██║     ██║
██████╔╝██║██║     █████╔╝ ███████║██╔██╗ ██║    ██║     ██║
██╔══██╗██║██║     ██╔═██╗ ██╔══██║██║╚██╗██║    ██║     ██║
██║  ██║██║╚██████╗██║  ██╗██║  ██║██║ ╚████║    ███████╗██║
╚═╝  ╚═╝╚═╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝    ╚══════╝╚═╝



Type 'help' to see list of available commands.`;
};
