import { type } from "os";

export * from "./social";
export { default as aboutme } from "./about";
export * from "./utils";
import { getWeather } from "./weather";
import * as React from "react";

export const weather = async () => {
    const weather = await getWeather();
    return `Getting the weather where I am <br/><span>${weather}</span>`;
};
