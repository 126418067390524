import React from "react";
import * as cmd from "./cmd";
import "../terminal.css";

export const shell = async (
    command: string,
    setHistory: (value: string) => void,
    clearHistory: () => void,
    setCommand: React.Dispatch<React.SetStateAction<string>>
) => {
    const args = command.split(" ");
    args[0] = args[0].toLowerCase();

    if (args[0] === "clear") {
        clearHistory();
    } else if (command === "") {
        setHistory("");
    } else if (Object.keys(cmd).indexOf(args[0]) === -1) {
        setHistory(
            `shell: command not found: ${args[0]}. Try 'help' to get started.`
        );
    } else {
        const output = await cmd[args[0]](args.slice(1));
        setHistory(output);
    }

    setCommand("");
};
