import React from "react";
import { HistoryInterface } from "./HistoryInterface";
import { userVar } from "../userVar";
import "/src/terminal/terminal.css";

export const History: React.FC<{ history: Array<HistoryInterface> }> = ({
    history,
}) => {
    return (
        <>
            {history.map((entry: HistoryInterface, index: number) => (
                <div key={entry.command + index}>
                    <div className={"input-hist"}>
                        {userVar()} {entry.command}
                    </div>
                    <p
                        className={"terminal_command"}
                        style={{ lineHeight: "normal" }}
                        dangerouslySetInnerHTML={{ __html: entry.output }}
                    />
                </div>
            ))}
        </>
    );
};

export default History;
