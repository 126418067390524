export const github = async (args: string[]): Promise<string> => {
    setTimeout(function () {
        window.open("https://github.com/rickyli888", "_blank");
    }, 1000);

    return "Opening github repository...";
};

export const linkedin = async (args: string[]): Promise<string> => {
    setTimeout(function () {
        window.open("https://www.linkedin.com/in/rickanli/");
    }, 1000);

    return "Opening linkedin page...";
};
