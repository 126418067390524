import React from "react";
import { HistoryInterface as History } from "./HistoryInterface";

export const useHistory = (defaultValue: Array<History>) => {
    const [history, setHistory] = React.useState<Array<History>>(defaultValue);
    const [command, setCommand] = React.useState<string>("");
    const [lastCommandIndex, setLastCommandIndex] = React.useState<number>(0);

    return {
        history,
        command,
        lastCommandIndex,
        setHistory: (value: string) =>
            setHistory([
                ...history,
                {
                    id: history.length,
                    date: new Date(),
                    command,
                    output: value,
                },
            ]),
        setCommand,
        setLastCommandIndex,
        clearHistory: () => setHistory([]),
    };
};
