import React, { useEffect, useState } from "react";
import "/src/terminal/terminal.css";

import { useHistory } from "../terminal/util/history/histHook";
import { History } from "../terminal/util/history/History";

import { Input } from "../terminal/util/Input";

const banner = () => {
    return `

██████╗ ██╗ ██████╗██╗  ██╗ █████╗ ███╗   ██╗    ██╗     ██╗
██╔══██╗██║██╔════╝██║ ██╔╝██╔══██╗████╗  ██║    ██║     ██║
██████╔╝██║██║     █████╔╝ ███████║██╔██╗ ██║    ██║     ██║
██╔══██╗██║██║     ██╔═██╗ ██╔══██║██║╚██╗██║    ██║     ██║
██║  ██║██║╚██████╗██║  ██╗██║  ██║██║ ╚████║    ███████╗██║
╚═╝  ╚═╝╚═╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝    ╚══════╝╚═╝


Type 'home' to return to the original site

Type 'help' to see list of available commands`;
};

const Terminal = () => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const onClickAnywhere = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const containerRef = React.useRef(null);

    const {
        history,
        command,
        lastCommandIndex,
        setCommand,
        setHistory,
        clearHistory,
        setLastCommandIndex,
    } = useHistory([]);

    const init = React.useCallback(() => "", []);

    React.useEffect(() => {
        init();
    }, [init]);

    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.scrollIntoView();
            inputRef.current.focus({ preventScroll: true });
        }
    }, [history]);

    /*const onClickAnywhere = () => {
        const inp = document.getElementById("term_input");
        if (inp != null) {
            inp.focus();
        }
    };*/

    return (
        <div className="terminal_main" onClick={onClickAnywhere}>
            <main>
                <div className="container_term" ref={containerRef}>
                    <div className={"term_head"}>
                        <pre className={"t_pre"}>
                            <span className="t_banner">{banner()}</span>
                        </pre>
                        <History history={history} />

                        <Input
                            inputRef={inputRef}
                            containerRef={containerRef}
                            command={command}
                            history={history}
                            lastCommandIndex={lastCommandIndex}
                            setCommand={setCommand}
                            setHistory={setHistory}
                            setLastCommandIndex={setLastCommandIndex}
                            clearHistory={clearHistory}
                        />
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Terminal;
