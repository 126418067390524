import * as cmd from "./cmd";
import * as React from "react";

export const handleTabCompletion = (
    command: string,
    setCommand: React.Dispatch<React.SetStateAction<string>>
) => {
    const commands = Object.keys(cmd).filter((entry) =>
        entry.startsWith(command)
    );

    if (commands.length === 1) {
        setCommand(commands[0]);
    }
};
