export const getWeather = async (): Promise<string | void> => {
    // https://wttr.in/san-diego?
    return await fetch("https://wttr.in/san-diego?AFT1", {
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                throw response;
            }
            return response.text();
        })
        .catch((error) => {
            console.log(error);
        });
};
