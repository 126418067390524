import React, { useState } from "react";
import { handleTabCompletion } from "./tabCompletion";
import { commandExists } from "./cmdExists";
import { shell } from "./shell";
import { userVar } from "./userVar";

import "/src/terminal/terminal.css";

export const Input = ({
    inputRef,
    containerRef,
    command,
    history,
    lastCommandIndex,
    setCommand,
    setHistory,
    setLastCommandIndex,
    clearHistory,
}) => {
    const onSubmit = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        const commands: [string] = history
            .map(({ command }) => command)
            .filter((command: string) => command);

        if (event.key === "c" && event.ctrlKey) {
            event.preventDefault();
            setCommand("");
            setHistory("");
            setLastCommandIndex(0);
        }

        if (event.key === "l" && event.ctrlKey) {
            event.preventDefault();
            clearHistory();
        }

        if (event.key === "Tab") {
            event.preventDefault();
            handleTabCompletion(command, setCommand);
        }

        if (event.key === "Enter" || event.code === "13") {
            event.preventDefault();
            setLastCommandIndex(0);
            await shell(command, setHistory, clearHistory, setCommand);
            containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
        }

        if (event.key === "ArrowUp") {
            event.preventDefault();
            if (!commands.length) {
                return;
            }
            const index: number = lastCommandIndex + 1;
            if (index <= commands.length) {
                setLastCommandIndex(index);
                setCommand(commands[commands.length - index]);
            }
        }

        if (event.key === "ArrowDown") {
            event.preventDefault();
            if (!commands.length) {
                return;
            }
            const index: number = lastCommandIndex - 1;
            if (index > 0) {
                setLastCommandIndex(index);
                setCommand(commands[commands.length - index]);
            } else {
                setLastCommandIndex(0);
                setCommand("");
            }
        }
    };

    const onChange = ({
        target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
        setCommand(value);
    };

    return (
        <div className="input-flex" id={"term_input"}>
            {userVar()}
            <input
                ref={inputRef}
                type="text"
                className="input_contain"
                autoFocus
                onBlur={(e) => e.target.focus()}
                style={{
                    color:
                        commandExists(command) || command === ""
                            ? "#A3BE8C"
                            : "#88C0D0",
                }}
                value={command}
                onChange={onChange}
                autoComplete="off"
                spellCheck="false"
                onKeyDown={onSubmit}
                autoCorrect="off"
                autoCapitalize="off"
            />
        </div>
    );
};
