import * as React from "react";

const aboutme = () => {
    return `
                                                  
             @@@@@@@@@@@@@                  🙋 aboutme: links and more
        @@@@               @@@@             -----------
      @@                       @@           📋 ABOUT
    @@                           @@           🏄 Rickan Li
  @@                               @@         ⌘ Software Engineer
 @@                         @@@     @@        🏎️ <u><a href="#" target="_blank">resume</a></u>
@@        @@@                        @@       🖥️ <u><a href="github.com/rickyli888" target="_blank">Github repo</a></u>
@@                                   @@     -----------
@@             .@@@@@@@@@@.          @@     ⚡ CONTACT 
 @@           @@          @@        @@        📬 <u><a href="mailto:$rickyli860@gmail.com" target="_blank">rickyli860@gmail.com</a></u>
  @@           @@        @@        @@         🕹️ <u><a href="https://github.com/rickyli888" target="_blank">github.com/rickyli888</a></u>
   @@             @@@@@@          @@          🏗️ <u><a href="https://linkedin.com/in/rickanli" target="_blank">linkedin.com/in/rickanli</a></u>
     @@@                        @@@         
        @@@                  @@@ @@        
         @|  @@@@@@@@@@@@@@@@   @@          
         @|                     @@
         @|                     @@
                  
`;
};

export default aboutme;
